import styled, { css } from 'styled-components';
import { color, breakpoint } from '@aib/design-tokens';

export const layoutPadding = css`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 1rem;

  @media all and (min-width: 0) and (max-width: 480px) and (orientation: landscape) {
    padding: 1.5rem;
  }

  @media (max-width: 839px) and (min-width: 480px) and (orientation: landscape) {
    padding: 1.5rem;
  }

  @media (min-width: 480px) {
    padding: 3rem;
  }

  @media (min-width: ${breakpoint.xLarge}) {
    padding: 60px 180px;
  }
`;

export const Page = styled.div`
  height: 100%;

  @media (min-width: 576px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 420px;
    height: 680px;
    margin: auto;
  }

  @media (min-width: ${breakpoint.xLarge}) {
    width: 700px;
    border: solid 1px ${color.grey02};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12),
      0 0 1px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const PageBody = styled.div`
  ${layoutPadding};

  &&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  @media (min-width: ${breakpoint.medium}) {
    background: ${color.baseWhite};
    position: relative;
  }

  @media (min-width: 576px) {
    border: 1px solid ${color.grey02};
    background: ${color.baseWhite};
    height: 100%;
    box-sizing: border-box;
  }
`;

export const PageHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 34px;
`;

export const PageTitle = styled.h1`
  margin-top: 1.6rem;
  margin-bottom: 0.2rem;
  color: ${color.grey07};
  font-size: 1.6rem;
  text-align: center;
`;

export const PageLogo = styled.img`
  width: 80px;
  height: 80px;
`;

export const ContentWrapper = styled.div`
  margin-top: 64px;
`;

export const ContentTitleContainer = styled.div`
  max-width: 300px;
  width: 100%;
`;

export const ContentTitle = styled.h2`
  font-family: 'aspira-bold', sans-serif;
  text-align: center;
  font-size: 20px;
  color: ${color.grey07};
`;

export const ContentBody = styled.div`
  font-size: 20px;
  margin-top: 2rem;
  text-align: center;
  color: ${color.grey06};
`;

import { call, put, takeEvery, all } from 'redux-saga/effects';
import * as types from './ciam.types';
import { getProfileData, postConnectApplication } from '../api';
import {
  connectApplicationFailure,
  connectApplicationSuccess,
  fetchProfileDataFailure,
  fetchProfileDataSuccess,
} from './ciam.actions';

export function* connectApplicationFn({ masApplicationId, oldAppId }) {
  try {
    const response = yield call(
      postConnectApplication,
      masApplicationId,
      oldAppId
    );
    if (response.ok) {
      yield put(connectApplicationSuccess());
      window.location.assign('/mortgages/tasks');
    } else {
      yield put(connectApplicationFailure());
    }
  } catch (e) {
    yield put(connectApplicationFailure());
  }
}

export function* connectApplicationSaga() {
  yield takeEvery(types.CONNECT_APPLICATION, connectApplicationFn);
}

export function* fetchProfileDataFn() {
  try {
    const response = yield call(getProfileData);
    if (response.ok) {
      yield put(fetchProfileDataSuccess(response.resp));
    }
    return response;
  } catch (e) {
    yield put(fetchProfileDataFailure());
  }
}

export function* fetchProfileSaga() {
  yield takeEvery(types.FETCH_PROFILE_DATA, fetchProfileDataFn);
}

export default function* root() {
  yield all({
    connectApplicationSaga: call(connectApplicationSaga),
    fetchProfileSaga: call(fetchProfileSaga),
  });
}

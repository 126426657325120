import React from 'react';
import PropTypes from 'prop-types';
import decode from 'querystring/decode';
import BrowserWarning from '../../Communications/BrowserWarning/BrowserWarning';
import CookieUtils from '../../../lib/cookieUtils';
import browserVersionWarning from '../../../lib/browserVersionWarning';
import PageError from '../../Animations/PageError/PageError';
import LoginDirectly from '../../Session/LoginDirectly/LoginDirectly';
import * as S from './SplashPage.styled.js';
import { CloudAnimations } from '../../Animations/Clouds/CloudAnimations';
import Link from '@aib/uikit-react/dist/Navigation/Link/Link';
import { isPrd, isPre } from '../../../lib/envUtil';
import AppVersion from '@aib/uikit-react/dist/App/AppVersion/AppVersion';
import packageJson from '../../../../package.json';

import DebugLinks from './DebugLinks';
import { LOG_IN_REGISTER, ROOT, DASHBOARD } from '../../../lib/urls';
import LogInOrRegisterPage from '../LoginRegisterPage/LogInOrRegisterPage.jsx';

const lang = {
  HEADING_TITLE: 'MyMortgage',
  BUTTONS_LOGIN: 'Log in',
  BUTTONS_GET_STARTED: 'Get started',
  SESSION_EXPIRED: 'Session Expired',
  SESSION_EXPIRED_DESCRIPTION:
    'You have been logged out due to inactivity. Please login again.',
  ADVISORY_NOTICE:
    'Never share log in details with anyone, as they can access your bank accounts.',
};

const OBSCURE_KEY = 'LLHHLL'; // Click sequence: LOGO LOGO HEADING HEADING LOGO LOGO

class SplashPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isPageLoaded: false,
      secretClickSequence: '123456',
    };
  }

  navigateToDashboard = () => {
    const targetType = process.env.REACT_APP_PROXY_TARGET_TYPE;
    let nextPage = DASHBOARD;
    if (targetType === 'JSON_SERVER') {
      CookieUtils.createMockCsrfCookies();
    } else if (targetType === 'WEBSPHERE') {
      nextPage = '/webapp/dashboard';
    }
    location.href = nextPage;
  };

  loginClick = () => {
    this.navigateToDashboard();
  };

  getStartedClick = () => {
    this.props.history.push(LOG_IN_REGISTER);
  };

  logoClick = () => {
    this.addSecretClick('L'); // L, for Logo
  };

  headingClick = () => {
    this.addSecretClick('H'); // H, for Heading
  };

  // Secret click sequence to show/hide the debug links in production
  addSecretClick = (char) => {
    const updatedSequence = `${this.state.secretClickSequence}${char}`.slice(1);
    this.setState({ secretClickSequence: updatedSequence });
  };

  areDebugLinksVisible = () => {
    if (this.state.secretClickSequence === OBSCURE_KEY) {
      // Leaving logging in to aid testing since this will behave differently in prd to any other env
      console.log('secret key entered');
      return true;
    }
    if (isPrd() || isPre()) {
      return false;
    }
    return !this.props.hideVersion;
  };

  renderLoginButton() {
    return (
      <S.ButtonStyled
        id="SplashButtonLogin"
        label={lang.BUTTONS_LOGIN}
        onClick={this.loginClick}
        buttonStyle="primary"
        isLoading={this.state.loading}
        name="SplashButtonLogin"
      />
    );
  }

  componentDidMount() {
    // Remove the full page loading spinner
    const fullPageSpinner = document.getElementById('full-page-spinner');
    fullPageSpinner && fullPageSpinner.classList.add('full-page-spinner--hide');

    // If the load is fast, wait and do a smooth render
    // If it's slow, >1.8s, do a progressive render to show that something is happenning
    window.addEventListener('load', this.handleLoad);
    setTimeout(this.handleLoad, 1800);
  }

  componentWillUnmount() {
    // Would normally clear the timeout in here.
    // But for some reason this isn't firing.
    // I think it's something to do with react-router and subpaths.
    window.clearTimeout('load', this.handleLoad, false);
  }

  handleLoad = () => {
    this.setState({ isPageLoaded: true });
  };

  render() {
    const isSessionExpired =
      decode(document.location.search.substr(1)).error === 'session-expired';
    const browserWarningType = browserVersionWarning();

    return (
      <S.SplashPageMain isVisible={this.state.isPageLoaded}>
        {browserWarningType && <BrowserWarning type={browserWarningType} />}

        {isSessionExpired && (
          <PageError
            title={lang.SESSION_EXPIRED}
            text={lang.SESSION_EXPIRED_DESCRIPTION}
            visible={isSessionExpired}
          />
        )}

        {this.areDebugLinksVisible() && <DebugLinks />}
        <CloudAnimations />

        {this.props.match.path.endsWith(LOG_IN_REGISTER) && (
          <LogInOrRegisterPage navigateToNextPage={this.navigateToDashboard} />
        )}

        {this.props.match.path.endsWith(ROOT) && (
          <S.SplashPage>
            <S.SplashPageBody>
              <S.SplashPageHead>
                <S.SplashPageLogo onClick={this.logoClick} />
                <S.SplashPageTitle onClick={this.headingClick}>
                  {lang.HEADING_TITLE}
                </S.SplashPageTitle>
              </S.SplashPageHead>
              <S.SplashAdvisoryNotice>
                <S.AdvisoryNoticeCell>
                  <S.InfoIcon />
                </S.AdvisoryNoticeCell>
                <S.AdvisoryNoticeCell>
                  <S.AdvisoryNoticeText>
                    {lang.ADVISORY_NOTICE}
                  </S.AdvisoryNoticeText>
                </S.AdvisoryNoticeCell>
              </S.SplashAdvisoryNotice>
              <S.SplashPageCtas>
                <S.ButtonStyled
                  id="SplashButtonActivateRegistration"
                  label={lang.BUTTONS_GET_STARTED}
                  buttonStyle="secondary"
                  onClick={this.getStartedClick}
                  fullWidth
                />
                {this.renderLoginButton()}
                <Link
                  href="https://aib.ie/our-products/mortgages/my-mortgage"
                  target="_blank"
                  size="medium"
                >
                  Learn more about MyMortgage
                </Link>
              </S.SplashPageCtas>
              <LoginDirectly history={this.props.history} />
            </S.SplashPageBody>
          </S.SplashPage>
        )}

        {!this.props.hideVersion && (
          <AppVersion version={packageJson.version} isVisible={!isPrd()} />
        )}
      </S.SplashPageMain>
    );
  }
}

SplashPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  hideVersion: PropTypes.bool,
};

export default SplashPage;
